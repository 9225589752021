import {
  CarOffer,
  CarPoliciesDetails,
  CarSearchInfo,
} from "~/apps/corporate/models/car.model";
import { CountryRestriction } from "~/apps/corporate/models/country-restriction.model";
import { CarPolicy } from "~/apps/corporate/models/policy.model";
import { User } from "~/apps/corporate/models/user.model";
import { CarSupplier, ISmCarsAgency } from "sm-types/sm-car-rentals";

export interface AddCarOfferToTravelRequest {
  additional_drivers: string[];
  e_out_of_policy?: boolean;
  policies_details: CarPoliciesDetails;
  search_token: string;
  supplier_offer_token: string;
}

export interface CarSearchCreationRequest {
  diff_dropoff: boolean;
  dropoff_date: string;
  dropoff_hour: number;
  dropoff_latitude?: number;
  dropoff_longitude?: number;
  dropoff_minute: number;
  dropoff_search?: string;
  pickup_date: string;
  pickup_hour: number;
  pickup_latitude: number;
  pickup_longitude: number;
  pickup_minute: number;
  pickup_search: string;
  travel_token: string;
  search_by_agency: boolean;
  pickup_agency_name?: string;
  pickup_agency_code?: string;
  dropoff_agency_name?: string;
  dropoff_agency_code?: string;
}

export interface GetCarLocationAutosuggestResponse {
  code: number;
  location_list: Array<{
    destinationId: number;
    name: string;
    type: string;
  }>;
  message: string;
  type: string;
}

export interface GetCarResponse {
  expirationTime: Date;
  policy: CarPolicy;
  supplierOffer: CarOffer;
}

export interface GetCarsListResponse {
  carList: CarOffer[];
  code: number;
  countryRestriction?: CountryRestriction;
  expirationTime: Date;
  filtersInfo: {
    minPrice: number;
    maxPrice: number;
    averagePrice: number;
    minDailyPrice: number;
    maxDailyPrice: number;
    averageDailyPrice: number;
    classesOptions: Array<{
      id: string;
      label: string;
    }>;
    rentalsOptions: Array<{
      id: string;
      label: string;
    }>;
    doorsOptions: string[];
  };
  message: string;
  policy: CarPolicy;
  searchInfo: CarSearchInfo;
  supplierSearchToken: string;
  travelerInfo: User;
  travelInfo: any;
  type: string;
}

export interface RecalculateCarOfferRequestAdditional {
  category: string;
  code: string;
  quantity?: string;
}

export interface RecalculateCarOfferRequest {
  additionals: RecalculateCarOfferRequestAdditional[];
  supplier_info: {
    supplier_offer_id: string;
    supplier: string;
  };
}

export interface RecalculateCarOfferResponse {
  expirationTime: string;
  offer: CarOffer;
}

export enum CarOrderBy {
  DISTANCE = "DISTANCE",
  PRICE = "PRICE",
}

export type ListCarAgenciesResponse = Partial<
  Record<CarSupplier, ISmCarsAgency[]>
>;
